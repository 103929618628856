<template>
    <div>
        <Nav />

        <Tittle :type="'general'">
            <h1 class="fadeInUp"><span></span>Faq Section</h1>
        </Tittle>

        <section class="experts_team_area sec_pad"> 
            <div class="container">
                <div class="row">
                
                    <div class="col-lg-3">
                    <div class="faq_tab">
                            <h6 class="f_p t_color3 f_600 f_size_20 mb_40">Quick Navigation</h6>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="course-tab" data-toggle="tab" href="#course" role="tab"
                                        aria-controls="course" aria-selected="true">Courses</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="student-tab" data-toggle="tab" href="#student" role="tab"
                                        aria-controls="student" aria-selected="false">Students</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="certificate-tab" data-toggle="tab" href="#certificate" role="tab"
                                        aria-controls="certificate" aria-selected="false">Certificates</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="instructor-tab" data-toggle="tab" href="#instructor" role="tab"
                                        aria-controls="instructor" aria-selected="false">Instructor</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="more-tab" data-toggle="tab" href="#more" role="tab"
                                        aria-controls="more" aria-selected="false">More</a>
                                </li>
                            </ul>
                        </div>
    
                    </div>
                    <!--/aside -->
                    
                    <div class="col-lg-9"> 
                        <div class="tab-content faq_content Cus_acc" id="myTabContent">
                            <div class="tab-pane fade show active" id="course" role="tabpanel"
                                aria-labelledby="course-tab">
                                <h3 class="f_p f_size_22 f_500 t_color3 mb_20">Courses</h3>
                                <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne"> How can I start a course?<i class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div class="card-body">
                                                Register or Login to Faslearn.com Once you login, you can search for a course of your choice. Select the course you want to study and select Start Now. If you require any further assistance, please email info@elearning.fmragency.com and our team will be happy to assist you in any way we can.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo"> What is the course duration? <i
                                                        class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                            data-parent="#accordion">
                                            <div class="card-body">The duration courses differ, and it is unique. Course duration is in the course outline and information.</div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">How long would I be in possession of the course I have purchase?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                            data-parent="#accordion">
                                            <div class="card-body">
                                                The course belongs to you forever once you purchase it. You can access the course at anytime and anywhere. If at any point you wish to revert to the course, you are at liberty to do so.
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                            </div>

                            <div class="tab-pane fade" id="student" role="tabpanel" aria-labelledby="student-tab">
                                <h3 class="f_p f_size_22 f_500 t_color3 mb_20">Students</h3>
                                <div id="accordion3">
                                    <div class="card">
                                        <div class="card-header" id="heading10">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapse10" aria-expanded="true"
                                                    aria-controls="collapse10">Invalid Login Details<i class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapse10" class="collapse show" aria-labelledby="heading10"
                                            data-parent="#accordion3">
                                            <div class="card-body">
                                                If you are having a problem logging in to your account or seeing the error message "Invalid Login Details", there may be several reasons for this: The email/ password combination you are entering is incorrect. There is a spelling mistake in the email/ password you have entered. You have registered your account, but you have forgotten your e-mail, kindly reset your password or e-mail us at
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading11">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse11" aria-expanded="false"
                                                    aria-controls="collapse11">How does course assessments work?<i
                                                        class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse11" class="collapse" aria-labelledby="heading11"
                                            data-parent="#accordion3">
                                            <div class="card-body">
                                                <p>Assessments are built into each course and are generally in the format of single and/or multiple-choice questions. Courses can have one or more assessments. You will be presented with the assessment after you complete the required course outline. You will be certified when you complete all the course material </p>
                                                <p>and achieve a result of 50% or more in every assessment, and you have only 3 times to try, if you do not meet up to the required percentage.</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>  

                            <div class="tab-pane fade" id="certificate" role="tabpanel" aria-labelledby="certificate-tab">
                                <h3 class="f_p f_size_22 f_500 t_color3 mb_20">Certificates</h3>
                                <div id="accordion4">
                                    <div class="card">
                                        <div class="card-header" id="heading15">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapse15" aria-expanded="true"
                                                    aria-controls="collapse15">How do I become certified in my Course?<i class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapse15" class="collapse show" aria-labelledby="heading15"
                                            data-parent="#accordion4">
                                            <div class="card-body">
                                                <p>To qualify for a Certificate, you must successfully complete your chosen course by achieving 50% or higher in all assessments. Once you complete the final assessment and pass with a result of 50% or more, the course would automatically update to complete. If you, however, fail an assessment or wish to attain a higher score, you can retake the assessment up to 3 times.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading16">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse16" aria-expanded="false"
                                                    aria-controls="collapse16">Who issues the certificate?<i
                                                        class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse16" class="collapse" aria-labelledby="heading16"
                                            data-parent="#accordion4">
                                            <div class="card-body">
                                                <p>The University or  Faslearn will be issuing the e-certificate. To make learning, mastery and certification more desirable for our learners, we have partnered with A-list Universities in Nigeria; hence, your certificate from us is globally recognized.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading17">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse17" aria-expanded="false"
                                                    aria-controls="collapse17">Why you should learn with us.<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse17" class="collapse" aria-labelledby="heading17"
                                            data-parent="#accordion4">
                                            <div class="card-body">	
                                                <p>The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn.” -Alvin Toffler. In today’s fast paced technology driven society, the quest for knowledge is as important as air itself and today’s learners want relevant, mobile, self-paced and personalized content. This need is fulfilled with our e-learning platform; here, students can learn at their own comfort, time, and requirement.</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                            </div>

                            <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                                <h3 class="f_p f_size_22 f_500 t_color3 mb_20">Instructor</h3>
                                <div id="accordion6">
                                    <div class="card">
                                        <div class="card-header" id="heading24">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapse24" aria-expanded="true"
                                                    aria-controls="collapse24">What is the mode of the course materials?<i class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapse24" class="collapse show" aria-labelledby="heading24"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>The course materials Should be a mixture of slides and voiceovers, videos, multimedia or a combination of all as well as other resources relevant to the student learning.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading25">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse25" aria-expanded="false"
                                                    aria-controls="collapse25">If I develop a course in an area, can I still develop another course in another area and launch it on the learning platform?<i
                                                        class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse25" class="collapse" aria-labelledby="heading25"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>Yes. But because we don’t want our learners to be overwhelmed, we advise that instructors limit the course to a particular area, rather than being generic. In situation where the trainer has expertise in other areas; areas outside the default industry, we encourage them to launch the course so long as the course remain insightful, engaging and meets out standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading26">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse26" aria-expanded="false"
                                                    aria-controls="collapse26">How can the course be uploaded?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse26" class="collapse" aria-labelledby="heading26"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>Your course can be uploaded through your instructor account. If you have difficulties uploading, please send an email to info@faslearn.com.  Do not give out your videos to any FMR representative without sending an email to the above provided mail and a go-ahead granted.</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            
                            <div class="tab-pane fade" id="more" role="tabpanel" aria-labelledby="instructor-tab">
                                <h3 class="f_p f_size_22 f_500 t_color3 mb_20">More</h3>
                                <div id="accordion6">
                                    <div class="card">
                                        <div class="card-header" id="heading24">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapse24" aria-expanded="true"
                                                    aria-controls="collapse24">I tried logging into my account but says I cannot. Then tried doing account recovery, it came back with info that I don't have an account.<i class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapse24" class="collapse show" aria-labelledby="heading24"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                If you have issues signing up or logging in, please inform your account manager or email info@faslearn.com for quick support.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading25">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse25" aria-expanded="false"
                                                    aria-controls="collapse25">I am a trained professional in a particular field with significant years of experience, but I do not have a certificate to show for it, what do I do?<i
                                                        class="ti-plus"></i><i class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse25" class="collapse" aria-labelledby="heading25"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>You can be a trainer without a certificate; however, the course must pass through all the guidelines and quality checks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading26">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse26" aria-expanded="false"
                                                    aria-controls="collapse26">Are we to develop the courses ourselves and upload them for review or we would be given a curriculum to follow that will aid the development of the course?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse26" class="collapse" aria-labelledby="heading26"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>We would require you to submit your course outline with us, to check its validity and obtain if it is fit for our learner’s purpose. If we are satisfied, you can go ahead to create your course according to how you desire using our guidelines, then it will go through a quality check and if it passes our quality checks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading27">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse27" aria-expanded="false"
                                                    aria-controls="collapse27">Who funds the content Development and Design?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse27" class="collapse" aria-labelledby="heading27"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>The instructor funds its development and designs</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading28">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse28" aria-expanded="false"
                                                    aria-controls="collapse28">How is payment done?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse28" class="collapse" aria-labelledby="heading28"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>
                                                    A.	If course is directly launched on the faslearn platform, payment will be made monthly from course sales 
                                                    <br>
                                                    B.	If course is directly developed for the university, Payment for your course is made immediately you course passes our quality assurance checks
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading27">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse27" aria-expanded="false"
                                                    aria-controls="collapse27">Can I teach other areas?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse27" class="collapse" aria-labelledby="heading27"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>Yes, you can teach other courses, if the content quality matches the required standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading27">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapse27" aria-expanded="false"
                                                    aria-controls="collapse27">Can I have the same course on another E-learning platform?<i class="ti-plus"></i><i
                                                        class="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse27" class="collapse" aria-labelledby="heading27"
                                            data-parent="#accordion6">
                                            <div class="card-body">
                                                <p>No, you cannot have the same exact course on another E-learning platform. However, you can have the same course content and title and sell it elsewhere, but it must not be the exact one you sold to us.</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>
                <!-- /row -->
            </div>
            <!--/container--> 
        </section>
		<!--/container-->
        <Footer />
    </div>
</template>

<script>
    import Nav from '@/components/site/Nav.vue'
    import Tittle from '@/components/site/Tittle.vue'
    import Footer from '@/components/site/Footer.vue'
    export default {
        name: 'Fag',
        components: {
            Nav,
            Tittle,
            Footer
        },
        mounted(){
            // let js = document.createElement('script')
            // js.setAttribute('src', 'js/main.js')
            // document.head.appendChild(js)
            // let jsc = document.createElement('script')
            // jsc.setAttribute('src', 'js/common_scripts.js')
            // document.head.appendChild(jsc)
        }
    }
</script>

<style>
	.pushing{
		height: 100px;
		width: 20px;
	}
	@import url("./asset/css/bootstrap.min.css"); 
	@import url("./asset/vendors/animation/animate.css"); 
	@import url("./asset/css/style.css");
	@import url("./asset/css/responsive.css"); 
</style>